/*eslint-disable*/
import React, { useState, useEffect } from "react";
// react components for routing our app without refresh
import { NavLink, useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.js";
import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import { Button } from "@material-ui/core";
import Wallet_Popup from "../../Model/wallet_popup.jsx";
import {useDispatch,useSelector} from "react-redux"
import {removeUser} from "../../redux-store/reducer.js"
import { removeAuthToken } from "../../helper/localstorage.js";
import { removeAuthorization } from "../../comman/axios.config.js";
import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";
import { type PublicClient, type WalletClient, useWalletClient, usePublicClient, useDisconnect, useNetwork ,useSwitchNetwork} from 'wagmi'

const useStyles = makeStyles(styles);

 function HeaderLinks({address, settoken, token, setaddress}) {
  const { disconnect,isSuccess,status } = useDisconnect()
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handlewithdraw =(()=>{
    navigate('/withdraw',{state:{address:address}});
  });

  const Logout=(()=>{
    disconnect()
    dispatch(removeUser())
    localStorage.removeItem("wallettype");
    localStorage.removeItem("account");
    removeAuthToken();
    removeAuthorization();
    navigate('/login');
  })

  const copyclipboard=((address)=>{
    copy(address);
    toast.success("Address copied successfully !")
  })

  return (
    <div className="home_page_menu">
       <List className={classes.list + " main_navbar"}>  
       <ListItem className={classes.listItem}>
          <NavLink to="/" className={classes.navLink}>Home</NavLink>
        </ListItem>
        {user && user.type && user.type != 'partner' && 
        <>
        <ListItem className={classes.listItem}>
          <NavLink to="/buy" className={classes.navLink}>Buy Token</NavLink>
        </ListItem>

        <ListItem className={classes.listItem}>
          <NavLink to="/deposit" className={classes.navLink}>Deposit</NavLink>
        </ListItem>
        </>
 }
        <ListItem className={classes.listItem}>
          <NavLink to="/withdraw" className={classes.navLink} >Withdraw</NavLink>
        </ListItem>
        <ListItem className={classes.listItem}>
          <NavLink to="/reports" className={classes.navLink}>Reports</NavLink>
        </ListItem>
        <ListItem className={classes.listItem}>
        {user && user.type && user.type == 'partner' && 
      
        <NavLink to="/partner-dashboard" className={classes.navLink}> partner Dashboard </NavLink>
        }</ListItem>
        <ListItem className={classes.listItem}>
      {user.address?
        <NavLink to="javascript:void(0);" className="menu_btn user_address"><span>{user.address}</span><i onClick={()=>copyclipboard(user.address)} className="fas fa-copy"></i></NavLink>:
        <NavLink to="javascript:void(0);" className="menu_btn" data-toggle="modal" data-target="#exampleModal">Connect Wallet</NavLink>}
        </ListItem>
        {user.balance&&<ListItem className={classes.listItem}>
        <span className="menu_btn" >{user.balance} INW</span>
        </ListItem>}
        <ListItem className={classes.listItem}>
        <span onClick={Logout} className="menu_btn" >Logout</span>
        </ListItem>
      </List>      
      <Wallet_Popup address={address} settoken={settoken}  token={token} setaddress={setaddress} /> 
    </div>
  );
}
export default HeaderLinks;
