import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "../components/Header/Header.js";
import FooterHome from "../components/Footer/FooterHome.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
import styles from "../assets/jss/material-kit-react/views/home.js";
import { Link } from "react-router-dom";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import {useDispatch,useSelector } from "react-redux"

const dashboardRoutes = [];

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();
  const user = useSelector((state)=>state.user)
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = React.useState('panel1');
  const [token, settoken] = useState();
  const [address, setaddress] = useState();
  const [tokennn, settokennn] = useState();
  const navigate = useNavigate();

  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    setCount(1);
  }, [count]);


  const { ...rest } = props;

  const handlewithdraw = (() => {
    navigate('/withdraw', { state: { address: address } });
  });
const handlelink=(()=>{
  navigate('/reports');
})
  return (
    <div>

      <ScrollToTopOnMount />
      <Header
        color="white"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo_01.png")} alt="logo" />}
        rightLinks={<HeaderLinks address={address} settoken={settoken}  token={token} setaddress={setaddress}/>}
        fixed
        changeColorOnScroll={{
          height: 100,
          color: "dark",
        }}
        {...rest}
      />
      <div className="page-header" name="home">
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12} lg={10} className="m-auto">
              <div className="fullwidth_box">
                <img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" />
                {/* <h1>Connect your token to withdraw your CT tokens in your wallet</h1> */}
                <h1>Revolutionize payments with Infiniteway</h1>
                {/* <p>Receive, send, store, exchange, and pay in cryptocurrency with a security audited crypto wallet.</p> */}
                <p>Pay, receive, buy and store with cryptocurrency with a security audited payment gateway—unlock the full potential of digital currencies today.</p>
                {user.address ?
                <>
                  <button className="primary_btn" onClick={handlewithdraw}>withdraw Your Token</button> 
                  <button className="primary_btn" style={{"margin-left":"10px"}} onClick={handlelink} >View Report</button>
                  </>:
                  <>
                   <button className="primary_btn" data-toggle="modal" data-target="#exampleModal">Connect Wallet</button>
                  
                  </>
                  }

              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
